// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vodafone--cIWCc {\n  width: 3rem;\n}\n\n.vf1--I45mO,\n.vf2--KQK6p {\n  fill-rule: nonzero;\n  stroke: none;\n  fill-opacity: 1;\n}\n\n.vf1--I45mO {\n  fill: rgb(230, 0, 0);\n}\n\n.vf2--KQK6p {\n  fill: rgb(255, 255, 255);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/VodafoneWithoutText/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;;EAEE,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".vodafone {\n  width: 3rem;\n}\n\n.vf1,\n.vf2 {\n  fill-rule: nonzero;\n  stroke: none;\n  fill-opacity: 1;\n}\n\n.vf1 {\n  fill: rgb(230, 0, 0);\n}\n\n.vf2 {\n  fill: rgb(255, 255, 255);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vodafone": "vodafone--cIWCc",
	"vf1": "vf1--I45mO",
	"vf2": "vf2--KQK6p"
};
export default ___CSS_LOADER_EXPORT___;
