// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--tm5NJ {\n  display: flex !important;\n  justify-content: center;\n  padding-left: 1.5em !important;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/TextInput/Date/index.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,uBAAuB;EACvB,8BAA8B;AAChC","sourcesContent":[".input {\n  display: flex !important;\n  justify-content: center;\n  padding-left: 1.5em !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--tm5NJ"
};
export default ___CSS_LOADER_EXPORT___;
