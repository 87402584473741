// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".logo--FV_lj {\n  object-fit: contain;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Partners/Protel/Logo/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB","sourcesContent":[".logo {\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "logo--FV_lj"
};
export default ___CSS_LOADER_EXPORT___;
