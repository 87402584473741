// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navigation--f_MLK {\n  display: flex;\n  align-items: center;\n  padding: var(--base-size) 0;\n}\n.navigation--f_MLK .navBoxes--EtQ2X {\n  flex: 1;\n}\n.navigation--f_MLK .navBoxes--EtQ2X .text--ilAzs {\n  font-size: 0.75rem;\n  white-space: nowrap;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/TableNav/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,OAAO;AACT;AACA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".navigation {\n  display: flex;\n  align-items: center;\n  padding: var(--base-size) 0;\n}\n.navigation .navBoxes {\n  flex: 1;\n}\n.navigation .navBoxes .text {\n  font-size: 0.75rem;\n  white-space: nowrap;\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "navigation--f_MLK",
	"navBoxes": "navBoxes--EtQ2X",
	"text": "text--ilAzs"
};
export default ___CSS_LOADER_EXPORT___;
