// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons--aisXH {\n  margin-right: 1em !important;\n}\n\n.buttonGroup--vjhu3 {\n  display: flex;\n  gap: 1rem;\n}\n\n.buttonGroupCenter--VOwhQ {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  margin-top: 3em;\n}\n\n.buttonGroupLeft--DpMya {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: left;\n}\n\n.buttonGroupRight--O6Frn {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: right;\n}\n\n.buttonWithTextAndIcon--HNBUX {\n  display: flex;\n  align-items: center;\n  justify-content: safe center;\n  gap: 1rem;\n  height: 100%;\n}\n\n.splitGroups--W0QEX {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Sass/buttons.module.scss"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC","sourcesContent":[".buttons {\n  margin-right: 1em !important;\n}\n\n.buttonGroup {\n  display: flex;\n  gap: 1rem;\n}\n\n.buttonGroupCenter {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  margin-top: 3em;\n}\n\n.buttonGroupLeft {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: left;\n}\n\n.buttonGroupRight {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: right;\n}\n\n.buttonWithTextAndIcon {\n  display: flex;\n  align-items: center;\n  justify-content: safe center;\n  gap: 1rem;\n  height: 100%;\n}\n\n.splitGroups {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttons": "buttons--aisXH",
	"buttonGroup": "buttonGroup--vjhu3",
	"buttonGroupCenter": "buttonGroupCenter--VOwhQ",
	"buttonGroupLeft": "buttonGroupLeft--DpMya",
	"buttonGroupRight": "buttonGroupRight--O6Frn",
	"buttonWithTextAndIcon": "buttonWithTextAndIcon--HNBUX",
	"splitGroups": "splitGroups--W0QEX"
};
export default ___CSS_LOADER_EXPORT___;
