// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--XRJI4 {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n.container--XRJI4 .content--fPC7F {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n@media (min-width: 960px) {\n  .container--XRJI4 {\n    flex-direction: row;\n  }\n}\n.wrapper--Ke7Iz {\n  max-width: 800px;\n  margin: auto !important;\n}\n.wrapper--Ke7Iz .content--fPC7F div {\n  margin: 0.75rem 0;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/SupplierSupport/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n.container .content {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n@media (min-width: 960px) {\n  .container {\n    flex-direction: row;\n  }\n}\n.wrapper {\n  max-width: 800px;\n  margin: auto !important;\n}\n.wrapper .content div {\n  margin: 0.75rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--XRJI4",
	"content": "content--fPC7F",
	"wrapper": "wrapper--Ke7Iz"
};
export default ___CSS_LOADER_EXPORT___;
