// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--CgFxC {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n.container--CgFxC .content--fv82c {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n@media (min-width: 960px) {\n  .container--CgFxC {\n    flex-direction: row;\n  }\n}\n.container--CgFxC {\n  overflow: visible;\n}\n.container--CgFxC .content--fv82c {\n  overflow: visible;\n}\n.container--CgFxC .content--fv82c .page--cJjP7 {\n  display: flex;\n  align-items: center;\n  justify-content: safe center;\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  overflow: auto;\n  background: white;\n}\n\n.link--NnVlH {\n  color: var(--text-link-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Auth/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,4BAA4B;EAC5B,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n.container .content {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n@media (min-width: 960px) {\n  .container {\n    flex-direction: row;\n  }\n}\n.container {\n  overflow: visible;\n}\n.container .content {\n  overflow: visible;\n}\n.container .content .page {\n  display: flex;\n  align-items: center;\n  justify-content: safe center;\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  overflow: auto;\n  background: white;\n}\n\n.link {\n  color: var(--text-link-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--CgFxC",
	"content": "content--fv82c",
	"page": "page--cJjP7",
	"link": "link--NnVlH"
};
export default ___CSS_LOADER_EXPORT___;
