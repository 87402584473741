// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popupCard--CwFNz {\n  width: 80%;\n  min-width: 15rem;\n  max-width: 70rem;\n  margin: auto;\n}\n.popupCard--CwFNz .close--kqAJ1 {\n  cursor: pointer;\n}\n.popupCard--CwFNz .items--VZbBC {\n  max-height: 10rem;\n  overflow-y: auto;\n}\n.popupCard--CwFNz .items--VZbBC .item--gpHXf {\n  margin-bottom: 0.5rem;\n}\n.popupCard--CwFNz .items--VZbBC .item--gpHXf .title--QaOY1 {\n  font-weight: bold;\n}\n\n@media (min-height: 300px) {\n  .items--VZbBC {\n    max-height: 18rem !important;\n  }\n}\n@media (min-height: 550px) {\n  .items--VZbBC {\n    max-height: 30rem !important;\n  }\n}\n@media (min-height: 700px) {\n  .items--VZbBC {\n    max-height: 35rem !important;\n  }\n}\n@media (min-width: 960px) {\n  .popupCard--CwFNz {\n    min-width: 40rem !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderResponses/PagePopup/Summary/AdditionalInfo/index.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,4BAA4B;EAC9B;AACF;AACA;EACE;IACE,2BAA2B;EAC7B;AACF","sourcesContent":[".popupCard {\n  width: 80%;\n  min-width: 15rem;\n  max-width: 70rem;\n  margin: auto;\n}\n.popupCard .close {\n  cursor: pointer;\n}\n.popupCard .items {\n  max-height: 10rem;\n  overflow-y: auto;\n}\n.popupCard .items .item {\n  margin-bottom: 0.5rem;\n}\n.popupCard .items .item .title {\n  font-weight: bold;\n}\n\n@media (min-height: 300px) {\n  .items {\n    max-height: 18rem !important;\n  }\n}\n@media (min-height: 550px) {\n  .items {\n    max-height: 30rem !important;\n  }\n}\n@media (min-height: 700px) {\n  .items {\n    max-height: 35rem !important;\n  }\n}\n@media (min-width: 960px) {\n  .popupCard {\n    min-width: 40rem !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popupCard": "popupCard--CwFNz",
	"close": "close--kqAJ1",
	"items": "items--VZbBC",
	"item": "item--gpHXf",
	"title": "title--QaOY1"
};
export default ___CSS_LOADER_EXPORT___;
