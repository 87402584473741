// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--fz1eQ {\n  display: none;\n}\n\n.cta--D1mrS {\n  background-color: var(--cta-button-color);\n  box-shadow: 0px 2px 2px var(--cta-button-color-hover);\n}\n.cta--D1mrS:hover {\n  background-color: var(--cta-button-color-hover);\n}\n.cta--D1mrS:active {\n  background-color: var(--cta-button-color-press);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/FileUploader/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,yCAAyC;EACzC,qDAAqD;AACvD;AACA;EACE,+CAA+C;AACjD;AACA;EACE,+CAA+C;AACjD","sourcesContent":[".input {\n  display: none;\n}\n\n.cta {\n  background-color: var(--cta-button-color);\n  box-shadow: 0px 2px 2px var(--cta-button-color-hover);\n}\n.cta:hover {\n  background-color: var(--cta-button-color-hover);\n}\n.cta:active {\n  background-color: var(--cta-button-color-press);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--fz1eQ",
	"cta": "cta--D1mrS"
};
export default ___CSS_LOADER_EXPORT___;
