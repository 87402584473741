// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--YMpoF {\n  width: 3rem;\n}\n\n.ee--VEAsl {\n  fill: #1d9f9e;\n  stroke: none;\n  fill-opacity: 1;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/EE/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".wrapper {\n  width: 3rem;\n}\n\n.ee {\n  fill: #1d9f9e;\n  stroke: none;\n  fill-opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--YMpoF",
	"ee": "ee--VEAsl"
};
export default ___CSS_LOADER_EXPORT___;
