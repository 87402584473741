// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--g92wZ {\n  font-weight: var(--title-font-weight);\n  font-size: 1.5rem;\n  font-family: \"NettoPro\", sans-serif;\n  line-height: 1.7rem;\n  letter-spacing: 0.08rem;\n}\n.title--g92wZ .primaryColor--fgjMc {\n  color: var(--primary-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/UsageUploadSensabill/title.module.scss"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,iBAAiB;EACjB,mCAAmC;EACnC,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,2BAA2B;AAC7B","sourcesContent":[".title {\n  font-weight: var(--title-font-weight);\n  font-size: 1.5rem;\n  font-family: \"NettoPro\", sans-serif;\n  line-height: 1.7rem;\n  letter-spacing: 0.08rem;\n}\n.title .primaryColor {\n  color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title--g92wZ",
	"primaryColor": "primaryColor--fgjMc"
};
export default ___CSS_LOADER_EXPORT___;
