// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container--zs7tT {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n.container--zs7tT .content--pTqhb {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n@media (min-width: 960px) {\n  .container--zs7tT {\n    flex-direction: row;\n  }\n}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Comparison/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,UAAU;EACV,sBAAsB;EACtB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n.container .content {\n  display: flex;\n  flex: auto;\n  flex-direction: column;\n  width: 100%;\n  overflow: hidden;\n}\n\n@media (min-width: 960px) {\n  .container {\n    flex-direction: row;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container--zs7tT",
	"content": "content--pTqhb"
};
export default ___CSS_LOADER_EXPORT___;
