// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--E3AgY {\n  position: relative;\n  align-self: center;\n}\n.wrapper--E3AgY .item--unEXM {\n  position: absolute;\n  top: 0;\n  left: 0;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Hardware/KeepingSome/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;AACT","sourcesContent":[".wrapper {\n  position: relative;\n  align-self: center;\n}\n.wrapper .item {\n  position: absolute;\n  top: 0;\n  left: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--E3AgY",
	"item": "item--unEXM"
};
export default ___CSS_LOADER_EXPORT___;
