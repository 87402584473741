// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".badgeWrapper--Jt_B4 {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.3em 0.5em;\n  text-align: center;\n  background-color: var(--alt-highlighted-text-color);\n  border-radius: 0.4rem;\n}\n.badgeWrapper--Jt_B4 .text--yP41b {\n  color: white;\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Badge/index.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,kBAAkB;EAClB,mDAAmD;EACnD,qBAAqB;AACvB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".badgeWrapper {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0.3em 0.5em;\n  text-align: center;\n  background-color: var(--alt-highlighted-text-color);\n  border-radius: 0.4rem;\n}\n.badgeWrapper .text {\n  color: white;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"badgeWrapper": "badgeWrapper--Jt_B4",
	"text": "text--yP41b"
};
export default ___CSS_LOADER_EXPORT___;
