// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".progressRail--g3QFg {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 16px;\n  padding: 0 0.5em;\n  background-color: rgb(240, 246, 246);\n  border-radius: 50px;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ProgressBar/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,oCAAoC;EACpC,mBAAmB;AACrB","sourcesContent":[".progressRail {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: 16px;\n  padding: 0 0.5em;\n  background-color: rgb(240, 246, 246);\n  border-radius: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressRail": "progressRail--g3QFg"
};
export default ___CSS_LOADER_EXPORT___;
