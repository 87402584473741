// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status--UDOzb {\n  color: red;\n  border-color: red;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Status/Lost/index.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".status {\n  color: red;\n  border-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "status--UDOzb"
};
export default ___CSS_LOADER_EXPORT___;
