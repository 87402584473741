// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--zQ4bj {\n  min-width: 2em;\n  max-width: 2em;\n}\n\n.setWidth--R13R3 {\n  width: 12rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Supplier/Nav/index.module.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".icon {\n  min-width: 2em;\n  max-width: 2em;\n}\n\n.setWidth {\n  width: 12rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--zQ4bj",
	"setWidth": "setWidth--R13R3"
};
export default ___CSS_LOADER_EXPORT___;
