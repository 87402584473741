// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title--uoYhm {\n  text-align: left;\n}\n\n.tableValue--aKD0K {\n  color: var(--title-main-color);\n}\n.tableValue--aKD0K.cost--P_JiD {\n  text-align: right;\n}\n.tableValue--aKD0K.total--JyrQJ {\n  font-weight: bold;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderResponses/PagePopup/Summary/Accordion/TariffTable/index.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".title {\n  text-align: left;\n}\n\n.tableValue {\n  color: var(--title-main-color);\n}\n.tableValue.cost {\n  text-align: right;\n}\n.tableValue.total {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title--uoYhm",
	"tableValue": "tableValue--aKD0K",
	"cost": "cost--P_JiD",
	"total": "total--JyrQJ"
};
export default ___CSS_LOADER_EXPORT___;
