// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--nk0ob {\n  gap: 1vw;\n  margin-bottom: 1em;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/Header/index.module.scss"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,2BAA2B;AAC7B","sourcesContent":[".header {\n  gap: 1vw;\n  margin-bottom: 1em;\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--nk0ob"
};
export default ___CSS_LOADER_EXPORT___;
