// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subHeader--qe2hG {\n  position: sticky;\n  top: 0;\n  padding: 0.7rem 1rem;\n  background-color: #fff;\n}\n.subHeader--qe2hG.marginIncrease--ihato {\n  margin-right: -1rem;\n  margin-left: -1rem;\n}\n.subHeader--qe2hG.flex--wgmQc {\n  display: flex;\n  align-items: center;\n}\n.subHeader--qe2hG.seperated--fwvAi {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 1rem;\n}\n.subHeader--qe2hG.noPadding--aT2NZ {\n  padding: 0;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/SubHeader/index.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,oBAAoB;EACpB,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;AACA;EACE,UAAU;AACZ","sourcesContent":[".subHeader {\n  position: sticky;\n  top: 0;\n  padding: 0.7rem 1rem;\n  background-color: #fff;\n}\n.subHeader.marginIncrease {\n  margin-right: -1rem;\n  margin-left: -1rem;\n}\n.subHeader.flex {\n  display: flex;\n  align-items: center;\n}\n.subHeader.seperated {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 1rem;\n}\n.subHeader.noPadding {\n  padding: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeader": "subHeader--qe2hG",
	"marginIncrease": "marginIncrease--ihato",
	"flex": "flex--wgmQc",
	"seperated": "seperated--fwvAi",
	"noPadding": "noPadding--aT2NZ"
};
export default ___CSS_LOADER_EXPORT___;
