// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--NfUqf {\n  display: none;\n}\n\n@media (min-width: 960px) {\n  .header--NfUqf {\n    display: flex;\n    align-items: center;\n    height: 3.5rem;\n    min-height: 52.5px;\n    padding-left: 1rem;\n    color: var(--inverted-text-color);\n    background: var(--standard-gradient);\n    font-weight: var(--title-font-weight);\n    font-size: 1.6rem;\n    font-family: \"NettoPro\", sans-serif;\n    line-height: 1.8rem;\n    letter-spacing: 0.08rem;\n  }\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Header/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;IACb,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,kBAAkB;IAClB,iCAAiC;IACjC,oCAAoC;IACpC,qCAAqC;IACrC,iBAAiB;IACjB,mCAAmC;IACnC,mBAAmB;IACnB,uBAAuB;EACzB;AACF","sourcesContent":[".header {\n  display: none;\n}\n\n@media (min-width: 960px) {\n  .header {\n    display: flex;\n    align-items: center;\n    height: 3.5rem;\n    min-height: 52.5px;\n    padding-left: 1rem;\n    color: var(--inverted-text-color);\n    background: var(--standard-gradient);\n    font-weight: var(--title-font-weight);\n    font-size: 1.6rem;\n    font-family: \"NettoPro\", sans-serif;\n    line-height: 1.8rem;\n    letter-spacing: 0.08rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--NfUqf"
};
export default ___CSS_LOADER_EXPORT___;
