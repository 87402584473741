// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--hHNCX {\n  flex-direction: column;\n  gap: 0.75rem;\n  width: 100%;\n  height: 100%;\n  padding: 0.75rem;\n  overflow-y: auto;\n  background-color: var(--card-color);\n  display: flex;\n  justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderEdit/Display/RightGutter/index.module.scss"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,mCAAmC;EACnC,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  flex-direction: column;\n  gap: 0.75rem;\n  width: 100%;\n  height: 100%;\n  padding: 0.75rem;\n  overflow-y: auto;\n  background-color: var(--card-color);\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--hHNCX"
};
export default ___CSS_LOADER_EXPORT___;
