// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loading--ibhpz {\n  width: 7rem;\n  height: 7rem;\n  border: 3px solid var(--border-color);\n  border-top-color: black;\n  border-radius: 50%;\n  -webkit-animation: spin--_Rjca 2s linear infinite;\n  animation: spin--_Rjca 2s linear infinite;\n}\n\n@-webkit-keyframes spin--_Rjca {\n  0% {\n    -webkit-transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin--_Rjca {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Loading/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,qCAAqC;EACrC,uBAAuB;EACvB,kBAAkB;EAClB,iDAA0C;EAC1C,yCAAkC;AACpC;;AAEA;EACE;IACE,+BAA+B;EACjC;EACA;IACE,iCAAiC;EACnC;AACF;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading {\n  width: 7rem;\n  height: 7rem;\n  border: 3px solid var(--border-color);\n  border-top-color: black;\n  border-radius: 50%;\n  -webkit-animation: spin 2s linear infinite;\n  animation: spin 2s linear infinite;\n}\n\n@-webkit-keyframes spin {\n  0% {\n    -webkit-transform: rotate(0deg);\n  }\n  100% {\n    -webkit-transform: rotate(360deg);\n  }\n}\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": "loading--ibhpz",
	"spin": "spin--_Rjca"
};
export default ___CSS_LOADER_EXPORT___;
