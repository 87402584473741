// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--T3Hgq {\n  position: relative;\n  display: inline-block;\n  margin-left: 2px;\n}\n.wrapper--T3Hgq .tooltip--OMBbq {\n  position: absolute;\n  z-index: 99999;\n  width: 20rem;\n  min-height: 1rem;\n  color: var(--text-color);\n  font-weight: normal;\n  font-size: 0.75rem;\n  line-height: 0.9rem;\n  white-space: pre-line;\n  pointer-events: none;\n}\n.wrapper--T3Hgq .tooltip--OMBbq .text--j0e0X {\n  background-color: white;\n  border-radius: 0.2em;\n  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/HoverTooltip/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,qBAAqB;EACrB,oBAAoB;AACtB;AACA;EACE,uBAAuB;EACvB,oBAAoB;EACpB,4CAA4C;EAC5C,4EAA4E;AAC9E","sourcesContent":[".wrapper {\n  position: relative;\n  display: inline-block;\n  margin-left: 2px;\n}\n.wrapper .tooltip {\n  position: absolute;\n  z-index: 99999;\n  width: 20rem;\n  min-height: 1rem;\n  color: var(--text-color);\n  font-weight: normal;\n  font-size: 0.75rem;\n  line-height: 0.9rem;\n  white-space: pre-line;\n  pointer-events: none;\n}\n.wrapper .tooltip .text {\n  background-color: white;\n  border-radius: 0.2em;\n  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--T3Hgq",
	"tooltip": "tooltip--OMBbq",
	"text": "text--j0e0X"
};
export default ___CSS_LOADER_EXPORT___;
