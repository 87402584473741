// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subHeaderText--HYY0P {\n  font-weight: var(--title-font-weight);\n  font-size: 1.1rem;\n  font-family: \"NettoPro\", sans-serif;\n  line-height: 1.3rem;\n  letter-spacing: 0.08rem;\n  font-weight: 600;\n}\n.subHeaderText--HYY0P .highlighted--Q58ZV {\n  color: var(--primary-color);\n}\n\n.content--FlAXF {\n  height: 100%;\n  overflow-y: auto;\n  background-color: var(--main-background-color);\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Register/Sensabill/index.module.scss"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,iBAAiB;EACjB,mCAAmC;EACnC,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,2BAA2B;AAC7B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,8CAA8C;EAC9C,4EAA4E;AAC9E","sourcesContent":[".subHeaderText {\n  font-weight: var(--title-font-weight);\n  font-size: 1.1rem;\n  font-family: \"NettoPro\", sans-serif;\n  line-height: 1.3rem;\n  letter-spacing: 0.08rem;\n  font-weight: 600;\n}\n.subHeaderText .highlighted {\n  color: var(--primary-color);\n}\n\n.content {\n  height: 100%;\n  overflow-y: auto;\n  background-color: var(--main-background-color);\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subHeaderText": "subHeaderText--HYY0P",
	"highlighted": "highlighted--Q58ZV",
	"content": "content--FlAXF"
};
export default ___CSS_LOADER_EXPORT___;
