// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".filterMenu--sMgrY {\n  margin-right: 2em;\n  color: var(--inverted-text-color);\n  font-size: 0.85rem;\n  background-color: var(--card-color);\n  -webkit-box-shadow: 0 0 2.4em rgba(93, 94, 94, 0.1);\n  box-shadow: 0 0 2.4em rgba(93, 94, 94, 0.1);\n}\n.filterMenu--sMgrY .filterTitle--sG0gN {\n  padding: calc(var(--standard-vertical-padding) * 0.5) calc(var(--standard-horizontal-padding) * 1);\n  font-weight: bold;\n  text-align: center;\n  background-color: var(--header-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/FilterMenu/index.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,iCAAiC;EACjC,kBAAkB;EAClB,mCAAmC;EACnC,mDAAmD;EACnD,2CAA2C;AAC7C;AACA;EACE,kGAAkG;EAClG,iBAAiB;EACjB,kBAAkB;EAClB,qCAAqC;AACvC","sourcesContent":[".filterMenu {\n  margin-right: 2em;\n  color: var(--inverted-text-color);\n  font-size: 0.85rem;\n  background-color: var(--card-color);\n  -webkit-box-shadow: 0 0 2.4em rgba(93, 94, 94, 0.1);\n  box-shadow: 0 0 2.4em rgba(93, 94, 94, 0.1);\n}\n.filterMenu .filterTitle {\n  padding: calc(var(--standard-vertical-padding) * 0.5) calc(var(--standard-horizontal-padding) * 1);\n  font-weight: bold;\n  text-align: center;\n  background-color: var(--header-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterMenu": "filterMenu--sMgrY",
	"filterTitle": "filterTitle--sG0gN"
};
export default ___CSS_LOADER_EXPORT___;
