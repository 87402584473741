// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--SjLHj {\n  position: relative;\n  display: flex;\n  border: 1px solid var(--border-color);\n  border-radius: 4px;\n}\n.wrapper--SjLHj:hover {\n  border-color: var(--primary-button-color);\n}\n.wrapper--SjLHj .error--d3vPr {\n  color: var(--error-color);\n  font-size: 0.75rem;\n}\n.wrapper--SjLHj .title--vPiKA {\n  display: flex;\n  align-items: center;\n  padding: 0 1.03em;\n  color: var(--text-color);\n  background-color: #e9ecef;\n  border-radius: 3px 0 0 3px;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/Dropdown/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,qCAAqC;EACrC,kBAAkB;AACpB;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,wBAAwB;EACxB,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".wrapper {\n  position: relative;\n  display: flex;\n  border: 1px solid var(--border-color);\n  border-radius: 4px;\n}\n.wrapper:hover {\n  border-color: var(--primary-button-color);\n}\n.wrapper .error {\n  color: var(--error-color);\n  font-size: 0.75rem;\n}\n.wrapper .title {\n  display: flex;\n  align-items: center;\n  padding: 0 1.03em;\n  color: var(--text-color);\n  background-color: #e9ecef;\n  border-radius: 3px 0 0 3px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--SjLHj",
	"error": "error--d3vPr",
	"title": "title--vPiKA"
};
export default ___CSS_LOADER_EXPORT___;
