// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--jrv7J {\n  width: 100%;\n  border: 1px solid var(--border-color) !important;\n  border-radius: 4px;\n}\n.input--jrv7J.noBorder--IeHc_ {\n  border-width: 0 !important;\n}\n.input--jrv7J:hover {\n  border-color: var(--primary-button-color) !important;\n}\n.input--jrv7J.error--aAKB1 {\n  color: var(--error-color) !important;\n  border-color: var(--error-color) !important;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/Calendar/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gDAAgD;EAChD,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,oDAAoD;AACtD;AACA;EACE,oCAAoC;EACpC,2CAA2C;AAC7C","sourcesContent":[".input {\n  width: 100%;\n  border: 1px solid var(--border-color) !important;\n  border-radius: 4px;\n}\n.input.noBorder {\n  border-width: 0 !important;\n}\n.input:hover {\n  border-color: var(--primary-button-color) !important;\n}\n.input.error {\n  color: var(--error-color) !important;\n  border-color: var(--error-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--jrv7J",
	"noBorder": "noBorder--IeHc_",
	"error": "error--aAKB1"
};
export default ___CSS_LOADER_EXPORT___;
