// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status--FfY9f {\n  color: white;\n  background-image: linear-gradient(90deg, #71bf49, #25abdd);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Status/Won/index.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,0DAA0D;AAC5D","sourcesContent":[".status {\n  color: white;\n  background-image: linear-gradient(90deg, #71bf49, #25abdd);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "status--FfY9f"
};
export default ___CSS_LOADER_EXPORT___;
