// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".group--m3Cll {\n  display: flex;\n  gap: 10px;\n  padding: calc(var(--standard-vertical-padding) * 0) calc(var(--standard-horizontal-padding) * 2);\n}\n.group--m3Cll .messages--ZLcBN {\n  display: flex;\n  flex: 1;\n  flex-direction: column-reverse;\n  gap: 10px;\n  margin-bottom: var(--standard-vertical-padding);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/MessageGroup/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,gGAAgG;AAClG;AACA;EACE,aAAa;EACb,OAAO;EACP,8BAA8B;EAC9B,SAAS;EACT,+CAA+C;AACjD","sourcesContent":[".group {\n  display: flex;\n  gap: 10px;\n  padding: calc(var(--standard-vertical-padding) * 0) calc(var(--standard-horizontal-padding) * 2);\n}\n.group .messages {\n  display: flex;\n  flex: 1;\n  flex-direction: column-reverse;\n  gap: 10px;\n  margin-bottom: var(--standard-vertical-padding);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"group": "group--m3Cll",
	"messages": "messages--ZLcBN"
};
export default ___CSS_LOADER_EXPORT___;
