// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--OTamJ {\n  position: relative;\n}\n.wrapper--OTamJ .number--jaPAX {\n  position: absolute;\n  top: 0.1rem;\n  left: 0.7rem;\n  color: #9a9a9a;\n  font-weight: 600;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/NumberedTick/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".wrapper {\n  position: relative;\n}\n.wrapper .number {\n  position: absolute;\n  top: 0.1rem;\n  left: 0.7rem;\n  color: #9a9a9a;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--OTamJ",
	"number": "number--jaPAX"
};
export default ___CSS_LOADER_EXPORT___;
