// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".baseStatus--LmJJS {\n  display: inline-flex;\n  align-items: center;\n  height: 20px;\n  padding: 4px 10px;\n  font-weight: 800;\n  font-size: 0.85rem;\n  text-align: center;\n  background-color: white;\n  border: 1px solid transparent;\n  border-radius: 1em;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Status/index.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;EACvB,6BAA6B;EAC7B,kBAAkB;AACpB","sourcesContent":[".baseStatus {\n  display: inline-flex;\n  align-items: center;\n  height: 20px;\n  padding: 4px 10px;\n  font-weight: 800;\n  font-size: 0.85rem;\n  text-align: center;\n  background-color: white;\n  border: 1px solid transparent;\n  border-radius: 1em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseStatus": "baseStatus--LmJJS"
};
export default ___CSS_LOADER_EXPORT___;
