// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".popup--cBob_ {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  width: 35em;\n  background-color: var(--card-color);\n  border: 1px solid var(--primary-button-color-press);\n  border-radius: 1.5rem;\n  box-shadow: 0px 1px 10px -5px #333;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Pages/Fallbacks/Uploading/index.module.scss"],"names":[],"mappings":"AAAA;EACE,4EAA4E;EAC5E,WAAW;EACX,mCAAmC;EACnC,mDAAmD;EACnD,qBAAqB;EACrB,kCAAkC;AACpC","sourcesContent":[".popup {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  width: 35em;\n  background-color: var(--card-color);\n  border: 1px solid var(--primary-button-color-press);\n  border-radius: 1.5rem;\n  box-shadow: 0px 1px 10px -5px #333;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popup": "popup--cBob_"
};
export default ___CSS_LOADER_EXPORT___;
