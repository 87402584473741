// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer--Z_wrK {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  gap: 1rem;\n  color: var(--text-color);\n  font-weight: bold;\n  background-color: var(--footer-color);\n  border-top: 0.1em solid var(--main-background-color);\n}\n.footer--Z_wrK .whiteBox--ZgojE {\n  z-index: 1000;\n  margin: -0.4rem;\n  padding: 0.4rem 0.4rem;\n  background-color: var(--footer-color);\n  border-radius: 0.75rem;\n}\n.footer--Z_wrK .whiteBox--ZgojE .privacy--iHBnv {\n  margin-left: 0.75rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Footer/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,4EAA4E;EAC5E,SAAS;EACT,wBAAwB;EACxB,iBAAiB;EACjB,qCAAqC;EACrC,oDAAoD;AACtD;AACA;EACE,aAAa;EACb,eAAe;EACf,sBAAsB;EACtB,qCAAqC;EACrC,sBAAsB;AACxB;AACA;EACE,oBAAoB;AACtB","sourcesContent":[".footer {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  gap: 1rem;\n  color: var(--text-color);\n  font-weight: bold;\n  background-color: var(--footer-color);\n  border-top: 0.1em solid var(--main-background-color);\n}\n.footer .whiteBox {\n  z-index: 1000;\n  margin: -0.4rem;\n  padding: 0.4rem 0.4rem;\n  background-color: var(--footer-color);\n  border-radius: 0.75rem;\n}\n.footer .whiteBox .privacy {\n  margin-left: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer--Z_wrK",
	"whiteBox": "whiteBox--ZgojE",
	"privacy": "privacy--iHBnv"
};
export default ___CSS_LOADER_EXPORT___;
