// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ZhiI_ {\n  position: relative;\n  text-align: center;\n}\n.wrapper--ZhiI_ .textBox--l8AxI {\n  position: absolute;\n  top: calc(55% - 2rem);\n  left: calc(50% - 5rem);\n  width: 10rem;\n  height: 4rem;\n  font-weight: var(--body-font-weight);\n  font-size: 0.95rem;\n  font-family: var(--body-font-family);\n  line-height: 1.25rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/TenderEdit/Popups/Welcome/StepCircle/index.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,YAAY;EACZ,YAAY;EACZ,oCAAoC;EACpC,kBAAkB;EAClB,oCAAoC;EACpC,oBAAoB;AACtB","sourcesContent":[".wrapper {\n  position: relative;\n  text-align: center;\n}\n.wrapper .textBox {\n  position: absolute;\n  top: calc(55% - 2rem);\n  left: calc(50% - 5rem);\n  width: 10rem;\n  height: 4rem;\n  font-weight: var(--body-font-weight);\n  font-size: 0.95rem;\n  font-family: var(--body-font-family);\n  line-height: 1.25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ZhiI_",
	"textBox": "textBox--l8AxI"
};
export default ___CSS_LOADER_EXPORT___;
