// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--zOreT {\n  width: 100%;\n  height: 10em;\n  padding: 0.5em;\n  overflow-y: scroll;\n  color: var(--text-color);\n  border-color: var(--border-color);\n  border-style: solid;\n  border-width: 0.1em;\n  border-radius: 0.2em;\n}\n.input--zOreT.error--Cb23E {\n  color: var(--error-color);\n  border-color: var(--error-color);\n}\n.input--zOreT:hover {\n  border-color: var(--primary-button-color);\n}\n.input--zOreT:focus {\n  outline: none;\n}\n\n.text--M4B3j {\n  font-weight: lighter !important;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/LargeInput/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,kBAAkB;EAClB,wBAAwB;EACxB,iCAAiC;EACjC,mBAAmB;EACnB,mBAAmB;EACnB,oBAAoB;AACtB;AACA;EACE,yBAAyB;EACzB,gCAAgC;AAClC;AACA;EACE,yCAAyC;AAC3C;AACA;EACE,aAAa;AACf;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".input {\n  width: 100%;\n  height: 10em;\n  padding: 0.5em;\n  overflow-y: scroll;\n  color: var(--text-color);\n  border-color: var(--border-color);\n  border-style: solid;\n  border-width: 0.1em;\n  border-radius: 0.2em;\n}\n.input.error {\n  color: var(--error-color);\n  border-color: var(--error-color);\n}\n.input:hover {\n  border-color: var(--primary-button-color);\n}\n.input:focus {\n  outline: none;\n}\n\n.text {\n  font-weight: lighter !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--zOreT",
	"error": "error--Cb23E",
	"text": "text--M4B3j"
};
export default ___CSS_LOADER_EXPORT___;
