// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ATG0J {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n.wrapper--ATG0J .typing--jmAxD {\n  display: flex;\n  width: 4em;\n}\n.wrapper--ATG0J .typing--jmAxD .dot--kGOLy {\n  margin: auto;\n  padding: 0.4em;\n  background-color: #000000;\n  border-radius: 100%;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/Typing/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,aAAa;EACb,UAAU;AACZ;AACA;EACE,YAAY;EACZ,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n.wrapper .typing {\n  display: flex;\n  width: 4em;\n}\n.wrapper .typing .dot {\n  margin: auto;\n  padding: 0.4em;\n  background-color: #000000;\n  border-radius: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ATG0J",
	"typing": "typing--jmAxD",
	"dot": "dot--kGOLy"
};
export default ___CSS_LOADER_EXPORT___;
