// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--v8l8W {\n  display: flex;\n  flex-direction: column;\n  border-right: 1px solid var(--main-background-color);\n}\n.wrapper--v8l8W .header--pHRlh .logo--xDlar {\n  height: 10vh;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}\n.wrapper--v8l8W .nav--gGa8j {\n  position: relative;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 10vh;\n  margin-top: 35%;\n}\n.wrapper--v8l8W .nav--gGa8j .navItem--Czxte {\n  z-index: 2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 7em;\n  height: 3em;\n  margin: 1em auto;\n  font-weight: bold;\n  font-size: 0.85rem;\n  text-align: center;\n  background-color: var(--card-color);\n  border-radius: 100%;\n}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Register/Page/LeftBar/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oDAAoD;AACtD;AACA;EACE,YAAY;EACZ,4EAA4E;AAC9E;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,OAAO;EACP,sBAAsB;EACtB,YAAY;EACZ,eAAe;AACjB;AACA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,mCAAmC;EACnC,mBAAmB;AACrB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  border-right: 1px solid var(--main-background-color);\n}\n.wrapper .header .logo {\n  height: 10vh;\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}\n.wrapper .nav {\n  position: relative;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  height: 10vh;\n  margin-top: 35%;\n}\n.wrapper .nav .navItem {\n  z-index: 2;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 7em;\n  height: 3em;\n  margin: 1em auto;\n  font-weight: bold;\n  font-size: 0.85rem;\n  text-align: center;\n  background-color: var(--card-color);\n  border-radius: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--v8l8W",
	"header": "header--pHRlh",
	"logo": "logo--xDlar",
	"nav": "nav--gGa8j",
	"navItem": "navItem--Czxte"
};
export default ___CSS_LOADER_EXPORT___;
