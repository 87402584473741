// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--QORII {\n  display: flex;\n  gap: calc(var(--standard-vertical-padding) * 2);\n  justify-content: center;\n  width: 100%;\n  margin-top: calc(var(--standard-vertical-padding) * 2);\n}\n.wrapper--QORII .section--f1KPM {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--standard-vertical-padding) * 2);\n  width: 35rem;\n}\n.wrapper--QORII .section--f1KPM .card--Xoa5G {\n  margin: 0;\n}\n.wrapper--QORII .section--f1KPM .card--Xoa5G .link--jsnE2 {\n  font-weight: var(--title-font-weight);\n  font-size: 1.1rem;\n  font-family: \"NettoPro\", sans-serif;\n  line-height: 1.3rem;\n  letter-spacing: 0.08rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/SmartComponents/User/MyAccount/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,+CAA+C;EAC/C,uBAAuB;EACvB,WAAW;EACX,sDAAsD;AACxD;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,+CAA+C;EAC/C,YAAY;AACd;AACA;EACE,SAAS;AACX;AACA;EACE,qCAAqC;EACrC,iBAAiB;EACjB,mCAAmC;EACnC,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".wrapper {\n  display: flex;\n  gap: calc(var(--standard-vertical-padding) * 2);\n  justify-content: center;\n  width: 100%;\n  margin-top: calc(var(--standard-vertical-padding) * 2);\n}\n.wrapper .section {\n  display: flex;\n  flex-direction: column;\n  gap: calc(var(--standard-vertical-padding) * 2);\n  width: 35rem;\n}\n.wrapper .section .card {\n  margin: 0;\n}\n.wrapper .section .card .link {\n  font-weight: var(--title-font-weight);\n  font-size: 1.1rem;\n  font-family: \"NettoPro\", sans-serif;\n  line-height: 1.3rem;\n  letter-spacing: 0.08rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--QORII",
	"section": "section--f1KPM",
	"card": "card--Xoa5G",
	"link": "link--jsnE2"
};
export default ___CSS_LOADER_EXPORT___;
