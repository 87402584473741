// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nav--W4UQW {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n.nav--W4UQW .link--NljCq {\n  display: flex;\n  justify-content: center;\n  width: 2em;\n  height: 2em;\n  line-height: 2em;\n  cursor: pointer;\n}\n.nav--W4UQW .link--NljCq.selected--Z7QWr {\n  color: var(--selected-input-color);\n}\n.nav--W4UQW .link--NljCq.unclickable--YvJIT {\n  cursor: auto;\n}\n.nav--W4UQW .link--NljCq:first-child {\n  border-radius: 5px 0 0 5px;\n}\n.nav--W4UQW .link--NljCq:last-child {\n  border-radius: 0 5px 5px 0;\n}\n.nav--W4UQW .link--NljCq.nextToSelected--FNJQr {\n  border-right: none;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Table/TableNav/Pagination/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,UAAU;EACV,WAAW;EACX,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,kCAAkC;AACpC;AACA;EACE,YAAY;AACd;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".nav {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n.nav .link {\n  display: flex;\n  justify-content: center;\n  width: 2em;\n  height: 2em;\n  line-height: 2em;\n  cursor: pointer;\n}\n.nav .link.selected {\n  color: var(--selected-input-color);\n}\n.nav .link.unclickable {\n  cursor: auto;\n}\n.nav .link:first-child {\n  border-radius: 5px 0 0 5px;\n}\n.nav .link:last-child {\n  border-radius: 0 5px 5px 0;\n}\n.nav .link.nextToSelected {\n  border-right: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav": "nav--W4UQW",
	"link": "link--NljCq",
	"selected": "selected--Z7QWr",
	"unclickable": "unclickable--YvJIT",
	"nextToSelected": "nextToSelected--FNJQr"
};
export default ___CSS_LOADER_EXPORT___;
