// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tag--BwVfc {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0.1em;\n  padding-left: 0.5em;\n  color: #0eabdb;\n  background: var(--tag-background-color);\n  border-radius: 0.2em;\n}\n.tag--BwVfc.chat--sjjnm {\n  padding: 4px 4px 4px 8px;\n  border-radius: 1em;\n}\n.tag--BwVfc .button--INcg5 {\n  display: block;\n  float: right;\n  padding: 0 0.5em;\n  background: none;\n  border: 0;\n  border-radius: 50%;\n  cursor: pointer;\n}\n.tag--BwVfc .button--INcg5:hover {\n  color: var(--delete-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Form/MultiTextAdd/Tag/index.module.scss"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,uCAAuC;EACvC,oBAAoB;AACtB;AACA;EACE,wBAAwB;EACxB,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,SAAS;EACT,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,0BAA0B;AAC5B","sourcesContent":[".tag {\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  margin: 0.1em;\n  padding-left: 0.5em;\n  color: #0eabdb;\n  background: var(--tag-background-color);\n  border-radius: 0.2em;\n}\n.tag.chat {\n  padding: 4px 4px 4px 8px;\n  border-radius: 1em;\n}\n.tag .button {\n  display: block;\n  float: right;\n  padding: 0 0.5em;\n  background: none;\n  border: 0;\n  border-radius: 50%;\n  cursor: pointer;\n}\n.tag .button:hover {\n  color: var(--delete-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": "tag--BwVfc",
	"chat": "chat--sjjnm",
	"button": "button--INcg5"
};
export default ___CSS_LOADER_EXPORT___;
