// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--NLIO9 {\n  position: sticky;\n  top: 0;\n  z-index: 100;\n  margin-right: -1.2em;\n  margin-left: -1.2em;\n  padding: 1em 2.5em 1em 2.5em;\n  background-color: #fff;\n  border-radius: 0;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/TenderBoxes/TenderFilter/index.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,4BAA4B;EAC5B,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".header {\n  position: sticky;\n  top: 0;\n  z-index: 100;\n  margin-right: -1.2em;\n  margin-left: -1.2em;\n  padding: 1em 2.5em 1em 2.5em;\n  background-color: #fff;\n  border-radius: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--NLIO9"
};
export default ___CSS_LOADER_EXPORT___;
