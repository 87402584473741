// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".icon--EoxbF {\n  width: 6em;\n  height: 6em;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/TopRated/index.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,WAAW;AACb","sourcesContent":[".icon {\n  width: 6em;\n  height: 6em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": "icon--EoxbF"
};
export default ___CSS_LOADER_EXPORT___;
