// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ZVNix {\n  width: 3rem;\n}\n\n.o2--Ea4mR {\n  fill: #0112aa;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/O2/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".wrapper {\n  width: 3rem;\n}\n\n.o2 {\n  fill: #0112aa;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ZVNix",
	"o2": "o2--Ea4mR"
};
export default ___CSS_LOADER_EXPORT___;
