// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".plan--AH8fg {\n  width: 4rem;\n}\n\n.st0--ej9NB {\n  fill: #169bed;\n}\n\n.st1--_htiw {\n  fill: #ffffff;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Icons/Networks/Plan.com/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":[".plan {\n  width: 4rem;\n}\n\n.st0 {\n  fill: #169bed;\n}\n\n.st1 {\n  fill: #ffffff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plan": "plan--AH8fg",
	"st0": "st0--ej9NB",
	"st1": "st1--_htiw"
};
export default ___CSS_LOADER_EXPORT___;
