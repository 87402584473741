// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mainTitle--OfNCa {\n  color: var(--alt-highlighted-text-color);\n  font-size: 1.2rem;\n}\n\n.tenderWrapper--UhicR {\n  display: flex;\n  justify-content: center;\n}\n.tenderWrapper--UhicR .addTender--e0NFr {\n  display: flex;\n  width: 25rem;\n  height: 16rem;\n  margin: 2vw;\n  background-color: white;\n  border-radius: 0.5em;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n.tenderWrapper--UhicR .addTender--e0NFr .content--xaLq2 {\n  width: 100%;\n}\n.tenderWrapper--UhicR .addTender--e0NFr .content--xaLq2 .createText--jmE86 {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 4rem;\n  color: var(--text-color);\n  font-size: 1.6rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/Apps/Broker/Pages/Dashboard/index.module.scss"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,WAAW;EACX,uBAAuB;EACvB,oBAAoB;EACpB,4EAA4E;AAC9E;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,wBAAwB;EACxB,iBAAiB;AACnB","sourcesContent":[".mainTitle {\n  color: var(--alt-highlighted-text-color);\n  font-size: 1.2rem;\n}\n\n.tenderWrapper {\n  display: flex;\n  justify-content: center;\n}\n.tenderWrapper .addTender {\n  display: flex;\n  width: 25rem;\n  height: 16rem;\n  margin: 2vw;\n  background-color: white;\n  border-radius: 0.5em;\n  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n}\n.tenderWrapper .addTender .content {\n  width: 100%;\n}\n.tenderWrapper .addTender .content .createText {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 4rem;\n  color: var(--text-color);\n  font-size: 1.6rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainTitle": "mainTitle--OfNCa",
	"tenderWrapper": "tenderWrapper--UhicR",
	"addTender": "addTender--e0NFr",
	"content": "content--xaLq2",
	"createText": "createText--jmE86"
};
export default ___CSS_LOADER_EXPORT___;
