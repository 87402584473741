// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--XvVOw {\n  width: 100%;\n  padding: 1em 0;\n}\n.wrapper--XvVOw .headers--qRo9R {\n  width: 100%;\n  margin-bottom: 1em;\n}\n.wrapper--XvVOw .headers--qRo9R .headerRight--zT9yK {\n  float: right;\n}\n\n.errorWrapper--F1Nxb.ticks--Vuf0I {\n  margin-top: 1.5em;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/SingleSlider/index.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;AAChB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".wrapper {\n  width: 100%;\n  padding: 1em 0;\n}\n.wrapper .headers {\n  width: 100%;\n  margin-bottom: 1em;\n}\n.wrapper .headers .headerRight {\n  float: right;\n}\n\n.errorWrapper.ticks {\n  margin-top: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--XvVOw",
	"headers": "headers--qRo9R",
	"headerRight": "headerRight--zT9yK",
	"errorWrapper": "errorWrapper--F1Nxb",
	"ticks": "ticks--Vuf0I"
};
export default ___CSS_LOADER_EXPORT___;
