// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".status--Ku8Y5 {\n  color: var(--tertiary-button-color);\n  border-color: var(--tertiary-button-color);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Status/Draft/index.module.scss"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,0CAA0C;AAC5C","sourcesContent":[".status {\n  color: var(--tertiary-button-color);\n  border-color: var(--tertiary-button-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"status": "status--Ku8Y5"
};
export default ___CSS_LOADER_EXPORT___;
