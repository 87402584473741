// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".input--a_DaF {\n  display: flex;\n  min-height: 3.5em;\n  padding: 0.3em;\n  background-color: var(--card-color);\n  border-radius: 5em;\n  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3490196078);\n}\n.input--a_DaF.multi--Y1Cj8 {\n  overflow: hidden;\n}\n.input--a_DaF .send--u2TzH {\n  display: flex;\n  justify-content: center;\n  width: 3em;\n}\n\n@media (min-width: 960px) {\n  .input--a_DaF {\n    width: 80%;\n    margin: auto;\n  }\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/TextInput/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;EACjB,cAAc;EACd,mCAAmC;EACnC,kBAAkB;EAClB,iDAAiD;AACnD;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;IACV,YAAY;EACd;AACF","sourcesContent":[".input {\n  display: flex;\n  min-height: 3.5em;\n  padding: 0.3em;\n  background-color: var(--card-color);\n  border-radius: 5em;\n  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3490196078);\n}\n.input.multi {\n  overflow: hidden;\n}\n.input .send {\n  display: flex;\n  justify-content: center;\n  width: 3em;\n}\n\n@media (min-width: 960px) {\n  .input {\n    width: 80%;\n    margin: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "input--a_DaF",
	"multi": "multi--Y1Cj8",
	"send": "send--u2TzH"
};
export default ___CSS_LOADER_EXPORT___;
