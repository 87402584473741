// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip--FzbYZ {\n  margin-top: var(--standard-vertical-padding);\n  background: var(--tag-background-color);\n  border: 1px solid var(--border-color);\n  border-radius: 2px;\n}\n.tooltip--FzbYZ .exit--G_ijL {\n  float: right;\n}\n.tooltip--FzbYZ .text--hfYkE {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  color: var(--text-color);\n  font-size: 0.85rem;\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/ChatBot/Tooltip/index.module.scss"],"names":[],"mappings":"AAAA;EACE,4CAA4C;EAC5C,uCAAuC;EACvC,qCAAqC;EACrC,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,4EAA4E;EAC5E,wBAAwB;EACxB,kBAAkB;AACpB","sourcesContent":[".tooltip {\n  margin-top: var(--standard-vertical-padding);\n  background: var(--tag-background-color);\n  border: 1px solid var(--border-color);\n  border-radius: 2px;\n}\n.tooltip .exit {\n  float: right;\n}\n.tooltip .text {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  color: var(--text-color);\n  font-size: 0.85rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip--FzbYZ",
	"exit": "exit--G_ijL",
	"text": "text--hfYkE"
};
export default ___CSS_LOADER_EXPORT___;
