// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip--LhP29 {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  margin-top: var(--standard-vertical-padding);\n  color: var(--text-color);\n  font-size: 0.85rem;\n  background: white;\n}\n.tooltip--LhP29 .border--jt0ci {\n  border-radius: 0.2em;\n  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}", "",{"version":3,"sources":["webpack://./src/gui/Components/Tooltip/index.module.scss"],"names":[],"mappings":"AAAA;EACE,4EAA4E;EAC5E,4CAA4C;EAC5C,wBAAwB;EACxB,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,4CAA4C;EAC5C,4EAA4E;AAC9E","sourcesContent":[".tooltip {\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n  margin-top: var(--standard-vertical-padding);\n  color: var(--text-color);\n  font-size: 0.85rem;\n  background: white;\n}\n.tooltip .border {\n  border-radius: 0.2em;\n  box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);\n  padding: var(--standard-vertical-padding) var(--standard-horizontal-padding);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": "tooltip--LhP29",
	"border": "border--jt0ci"
};
export default ___CSS_LOADER_EXPORT___;
